<template>
    <base-layout :public="true" :fill-height="true">
        <v-layout row wrap text-xs-center py-4>
            <v-flex xs10 sm10 md8 lg6 offset-xs1 offset-sm1 offset-md2 offset-lg3>
                <div class="display-1 font-weight-bold">
                    Your browser does not support yet!
                </div>
                <div class="title font-weight-bold pt-2">
                    <a href="https://www.google.com/intl/us/chrome/">Download latest Google&nbsp;Chrome</a>
                </div>
            </v-flex>
        </v-layout>
    </base-layout>
</template>

<script>
export default {
    name: 'UpdateBrowser',
    data() {
        return {};
    },
};
</script>
